<template>
  <div class="main-wrapper">
    <logo />
    <h1 class="mb-8 text-xl leading-8.5">
      {{ $t('new-password-view.set-password') }}
    </h1>
    <well class="w-136">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(send)">
          <ValidationProvider
            v-slot="{ errors }"
            rules="required|min|password:@confirm"
          >
            <text-input
              v-model="password"
              :placeholder="$t('new-password-view.new-password')"
              :error-message="errors[0]"
              type="password"
              class="w-full mb-6"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="confirm"
            vid="confirm"
            rules="required"
          >
            <text-input
              v-model="confirmation"
              :placeholder="$t('new-password-view.repeat-password')"
              :error-message="errors[0]"
              type="password"
              class="w-full mb-6"
            />
          </ValidationProvider>
          <btn :disabled="loading" type="submit" class="w-full">
            <span v-if="!loading">{{ $t('common.save') }}</span>
            <IconSpinner v-else />
          </btn>
        </form>
      </ValidationObserver>
    </well>
    <router-link
      :to="{ name: 'ForgotPassword' }"
      class="mt-4.5 opacity-40 text-sm hover:opacity-100"
    >
      {{ $t('login-view.forgot-password') }}
    </router-link>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mapActions } from 'vuex';
import IconSpinner from '@/components/Icon/icons/IconSpinner.vue';
import { TextInput } from '@/components/Inputs';
import Btn from '@/components/Button/Button.vue';
import Well from '@/components/Well/Well.vue';
import Logo from '@/components/Logo/Logo';

export default {
  components: {
    IconSpinner,
    TextInput,
    Btn,
    Well,
    ValidationProvider,
    ValidationObserver,
    Logo,
  },
  data() {
    return {
      confirmation: '',
      password: '',
      loading: false,
      apiError: false,
    };
  },
  created() {
    extend('required', {
      ...required,
      message: this.$t('new-password-view.password-error-empty'),
    });

    extend('min', {
      validate(value) {
        return value.length >= 6;
      },
      message: this.$t('new-password-view.password-error-min-length'),
    });

    extend('password', {
      params: ['target'],
      validate(value, { target }) {
        return value === target;
      },
      message: this.$t('new-password-view.password-error-no-match'),
    });
  },
  methods: {
    ...mapActions('user', ['newPassword']),
    async send() {
      this.loading = true;
      try {
        await this.newPassword({
          newPassword: this.password,
          token: this.$route.query.token,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.apiError = true;
      }
    },
  },
};
</script>
<style scoped>
.main-wrapper {
  @apply p-4 flex flex-col items-center justify-center h-screen text-white bg-cover;
  background-image: url('../assets/background.png');
}
</style>
